$colorPrimary: #002199;
$colorSecondary: #4bca81;
$colorDanger: #d20000;
$color1: #16325c;
$color2: #54698d;
$color3: #a8b7c7;
$color4: #e0e5ee;

$W1650: 1650.98px;
$W1590: 1590.98px;
$W1540: 1540.98px;
$W1500: 1500.98px;
$W1470: 1470.98px;
$W1400: 1400.98px;
$W1375: 1375.98px;
$W1230: 1230.98px;
$W1200: 1200.98px;
$W1170: 1170.98px;
$W1100: 1100.98px;

$H650: 650px;

@mixin mediaDesktop($width) {
  @media only screen and (max-width: #{$width}) {
    @content;
  }
}
@mixin mediaDesktopHeight($height) {
  @media only screen and (max-height: #{$height}) {
    @content;
  }
}
