@mixin clearfix {
    clear: both;
    &:before,
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin button {
    display: inline-block;
    border: none;
    padding: 0 10px;
    margin: 0;
    outline: none;
    background: transparent;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
}

@mixin scan-box {
    margin-top: 20px;
    margin-bottom: 40px;
    background: #ffffff;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 25px 40px 25px 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .icon {
        width: 88px;
        height: 88px;
        &.progress {
            background: url('../assets/img/scan-progress.gif') no-repeat;
            background-size: 88px 88px;
        }
        &.done {
            background: url('../assets/img/scan-ok.gif') no-repeat;
            background-size: 88px 88px;
        }
    }
    .info {
        font-size: 32px;
        line-height: 32px;
        color: #1a1133;
        .tip {
            font-size: 14px;
            display: none;
        }
    }
    .btn-wrap {
        width: 170px;
        text-align: right;
        .btn {
            width: 100%;
        }
    }
    &.done {
        .tip {
            display: block;
        }
    }
}
