@import "~ng-zorro-antd/ng-zorro-antd.less";

@font-family: 'Wix Madefor Display', sans-serif;
@primary-color: #002199;


@btn-primary-bg: #1A4CFF;

@error-color: #c23934;

@tabs-hover-color: #002199;
@tabs-highlight-color: #002199;
@tabs-ink-bar-color: #002199;

@box-shadow-base: 'none';


// ! TODO: implement this after call with design team

// Text style

// @text-color: #1a1133;
// @background-color-light: #E5E4ED; // thead background color
// @heading-color: #1a1133;


// Element colors

// @select-selection-item-bg: #00a79d;
@switch-color: #00a79d;
@checkbox-color: #00a79d;
@select-item-active-bg: #D7F0E1; // Item hover background color
@select-item-selected-bg: #f0f2f5;

// @select-border-color: #00a79d;

// Border radius style

// @border-radius-base: 8px;
// @border-radius-sm: 2px;
// @checkbox-border-radius: @border-radius-sm;
// @collapse-panel-border-radius: @border-radius-sm;
// @btn-border-radius-base: @border-radius-sm;
// @tooltip-border-radius: @border-radius-sm;
// @modal-border-radius: @border-radius-sm;
// @table-border-radius-base: @border-radius-sm;
// @tag-border-radius: @border-radius-sm;
// @card-radius: @border-radius-sm;